/* :root {
  --pagetopmargin: 5cm;
} */

/* :root {
  --pagetopmargin: 5cm;
} */

.active {
  color: red;
}

/* #tblDirectBilling>thead tr {
    background-color: royalblue;
} */

.right {
  text-align: right !important;
}
.left {
  text-align: left !important;
}
.center {
  text-align: center !important;
}
/* *************************************************** */
.tbl {
  width: 100%;
  border: 1px solid #b3adad;
  border-collapse: collapse;
  padding: 5px;
}
.tbl th {
  border: 1px solid #b3adad;
  padding: 5px;
  background: #f0f0f0;
  color: #313030;
}
.tbl td {
  border: 1px solid #b3adad;
  text-align: center;
  padding: 5px;
  background: #ffffff;
  color: #313030;
}
/* *************************************************** */

/* *************************************************** */
#tblGeneral {
  width: 100%;
  border: 1px solid #b3adad;
  border-collapse: collapse;
  padding: 5px;
}
#tblGeneral th {
  border: 1px solid #b3adad;
  padding: 5px;
  background: #f0f0f0;
  color: #313030;
}
#tblGeneral td {
  border: 1px solid #b3adad;
  text-align: center;
  padding: 5px;
  background: #ffffff;
  color: #313030;
}
/* *************************************************** */

#tblDirectBilling {
  width: 100%;
  border: 1px solid #b3adad;
  border-collapse: collapse;
  padding: 5px;
}
#tblDirectBilling th {
  border: 1px solid #b3adad;
  padding: 5px;
  background: #f0f0f0;
  color: #313030;
}
#tblDirectBilling td {
  border: 1px solid #b3adad;
  text-align: center;
  padding: 5px;
  background: #ffffff;
  color: #313030;
}

#tblOpdBillChargesList tbody > tr {
  /* height: 50px; */
  /* background: #f0f0f0; */
  border: 3px solid #b3adad;
  /* margin:0px;
    padding:7px; */
}

#tblOpdBillChargesList tr td {
  /* height: 50px; */
  /* background: #f0f0f0; */
  /* border:1px solid #b3adad; */
  margin: 0px;
  padding: 7px;
}
#tblOpdBillChargesList tr:hover {
  /* height: 50px; */
  background: #f0f0f0;
  /* margin:0px;
    padding:7px; */
}

/* *********************************************** */

/* @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey * /
    border-top: 10px solid #383636; /* Blue * /
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
  
  .spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 350px;
  } */

/* *********************************************** */

/* @media print {
  table1,
  img1,
  svg1 {
    break-inside: avoid;
  }
} */

/* *********************************************** */

/* table, */
img,
svg {
  break-inside: avoid;
  /* break-inside: avoid-page; */
}

/* thead {
  display: table-header-group;
}
tfoot {
  display: table-footer-group;
} */

/* Important Below Page size will Never Changed */
@page {
  size: A4;
  /* margin: 1cm; */
  margin-top: 1cm;
  margin-bottom: 1cm;
  margin-left: 1.5cm;
  margin-right: 1cm;
}

/* @media print {
  @page {
    @bottom-center {
      /* content: "Page " counter(12345); * /
      content: "Page: ";
    }
  }
} */

/* *********************************************** */

.fontsize11 {
  font-size: 11px;
}

.fontsize12 {
  font-size: 12px;
}

.fontsize13 {
  font-size: 13px;
}

.fontsize14 {
  font-size: 14px;
}

.fontsize15 {
  font-size: 15px;
}

.fontsize16 {
  font-size: 16px;
}

.fontsize17 {
  font-size: 17px;
}

.fontsize18 {
  font-size: 18px;
}

/* *********************************************** */

.lab-report-tblOnOnePage{
  break-inside: avoid;
}

/* *********************************************** */