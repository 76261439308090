/* CSS Module for LabReportPrint */
/* :root {
  --color-logo: blue;
} */

/* :root {
  --page-top-margin: 3in;
} */

/* .Applogo {
  color: var(--color-logo);
} */

/* @media print {
  .Applogo {
    color: var(--color-logo);
  }
} */

/* @page{orphans:4; widows:2;} */

/* https://developer.mozilla.org/en-US/docs/Web/CSS/@page */
/* @page {
  size: A4;  
  /* margin-top: var(--page-top-margin); * /
  margin-top:  3cm;
  margin-bottom: 2cm;
  margin-left: 5cm;
  margin-right: 1cm;

  @top-right {
    content: "Page " counter(pageNumber);
  }
} */

@media print {

  /* .lab-report-footer-barcode{
    width: 100% !important;
    position: absolute !important;
    bottom: 0 !important;
    text-align: center !important;
    /* border:2px solid blue; * /
    background-color: green !important;
  } */

  /* table {
    /* display: block !important; * /
    page-break-inside:avoid;
  } */

  /* table1,
  img1,
  svg1 {
    break-inside: avoid;
  }   */

  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }

  /* .pageFooter {
    /* position: absolute; <-- here show footer on First Page Only */
  /* position: fixed; <-- here show footer on every Pages */
  /* position: static; <-- Key line * /
    position: fixed;
    bottom: 15px;
    left: 50px;
    width: 100%;
    background: red;
  } */
}

/* Applogo1 {
  color: red;
  font-size: 33px;
} */

/* @media screen {
    thead {
      display: block;
    }
    tfoot {
      display: block;
    }
} */

/* @page :left {
      @bottom-left {
        content: counter(page);
     }
    } */

/* *********************************************** */
/* .twoColorInTableTd {
  background: linear-gradient(to right, lightgray 50%, rgb(241, 143, 126) 50%) !important;
} */

/* *********************************************** */

/* thead {
  display: table-header-group;
}
tfoot {
  display: table-footer-group;
} */

/* #labMainTable>thead {
  background-color: blue;
  display: table-header-group;
} */

/* Below code used in LabReport */
/* .lab-header {
  height: 120px;
  margin-bottom: 10px;
  text-align: center;
} */

/* .lab-header-dumy-space {
  height: 130px;
  display: none;
  text-align: center;
  margin-bottom: 20px;
} */

/* .lab-header {
  height: 180px;
  margin-bottom: 10px;
  text-align: center;
  background-color: aqua;
  /* margin-top: 100px; * /
} */

/* .lab-header-dumy-space {
  /*here height maximum limit: 305px; * /
  height: 100px;
  display: none;
  text-align: center;
  margin-bottom: 20px;
  background-color: rgb(240, 133, 208);
} */

/* .lab-footer {
  height: 150px; 
  padding-left: 150px;
  /* margin-top: 150px; * /
  text-align: left;
  font-weight: bold;
  font-size: 18px;
  padding-top: 40px;
  width: 100%;
}
.lab-footer-dumy-space {
  height: 150px;
  margin-top: 20px;
  display: none;
} */

/* @media print {   */
/* .labReportBody1 {
    display: table;
    table-layout: fixed;    
    height: auto;
  } */

/* .labReportHeader1 {
    width: 100%;
    /* position: absolute; * /
    top: 20px;
    height: 60px;
    /* background: rgb(242, 116, 122); * /
    /* display: block; * /
    display: table;
    table-layout: fixed;
  } */

/* .labReportFooter1 {
    padding-left: 150px;
    /* margin-top: 150px, * /
    text-align: left;
    font-weight: bold;
    font-size: 18px;
    /* bottom: 0; * /
    bottom: 0px;
    position: fixed;
    /* position: absolute; */
/* position:static; */
/* margin-top: 20px; */
/* display: block; * /
    width: 100%;
    height: 60px;
    /* padding-bottom: 60px; */
/* background: #6cf; * /
  } */

/* .lab-header {
      position: fixed;
      /* top: 120; */
/* top: 20px; * /
      text-align: center;
      /* display: block; * /
    } */

/* .lab-footer {
      position: fixed;
      bottom: 0;
      /* page-break-after: always; * /
    } */

/* .lab-incharge { 
      position: static;
      bottom: 20px;
      left: 20px;
      width: 100%;
      background: gray; 
    } */

/* .lab-header-dumy-space,
    .lab-footer-dumy-space {
      /* height: 50px; * /
      display: block;
    }  */
/* } */
